import { useRouter } from 'next/router';
import { z, ZodDefault, ZodOptional } from 'zod';
import { AnyZod, z_params } from '../utils/zod';

export function useQueryParamsRaw() {
  const router = useRouter();
  return router.query;
}

export function buildQueryParamsHook<
  Args extends {
    [key: string]:
      | AnyZod<any, string>
      | ZodOptional<AnyZod<any, string>>
      | ZodDefault<AnyZod<any, string>>;
  },
>(args: Args) {
  const schema = z_params(args);

  return function useQueryParams(initialValues?: z.input<typeof schema>) {
    return schema.parse({
      ...initialValues,
      ...useQueryParamsRaw(),
    });
  };
}

export type GetQueryParams<T extends (initialValues?: any) => any> = NonNullable<Parameters<T>[0]>;

export type GetQueryParamsResult<T extends (initialValues?: any) => any> = ReturnType<T>;
