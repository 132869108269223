import { SEOHead } from '@/components/@next/SEOHead';
import { LoginPage } from '@/features/auth/pages/LoginPage/LoginPage';
import NextApp from '../../NextApp';

export default function Login() {
  return (
    <NextApp>
      <SEOHead
        title="Login to STC"
        description="Login to Secure trade club and enjoy buying, selling and trading in a safe platform."
      />
      <LoginPage />
    </NextApp>
  );
}
