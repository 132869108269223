import CustomRedirect from '@/components/CustomRedirect';
import Loader from '@/components/loader/loader';
import { useCurrentUserId } from '@/features/user/currentUser.service';
import { buildQueryParamsHook, GetQueryParams } from '@/hooks/useQueryParams';
import routes from '@/routes/routes';
import { zodResolver } from '@hookform/resolvers/zod';
import NextLink from 'next/link';
import { Controller, useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { z } from 'zod';
import { login } from '../../auth.service';
import CustomInput from '../../components/CustomInput';
import CustomPasswordInput from '../../components/CustomPasswordInput';
import { FacebookLogin } from '../../components/FacebookLogin';
import { useLoginWithPasswordMutation } from '../../login.api';
import { Schema } from './LoginPage.schema';

const useLoginQueryParams = buildQueryParamsHook({
  returnUrl: z.string().optional(),
});
export type LoginQueryParams = GetQueryParams<typeof useLoginQueryParams>;

export const LoginPage = () => {
  const { returnUrl } = useLoginQueryParams();
  const loginMutation = useLoginWithPasswordMutation({
    onSuccess: (data) => {
      const { user, accessToken } = data;
      login({ user, accessToken, returnUrl });
    },
  });
  const loader = useSelector((state: RootStateOrAny) => state.loaderReducer.pageLoader);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Schema>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    resolver: zodResolver(Schema),
  });

  const onLogin = handleSubmit((data) => {
    const { email, password } = data;
    loginMutation.mutate({
      email,
      password,
    });
  });

  const currentUserId = useCurrentUserId();
  if (currentUserId) return <CustomRedirect to={routes.showcase({ userId: currentUserId })} />;

  return (
    <>
      {(loader || loginMutation.isLoading) && <Loader />}
      <div className="auth-container">
        <div className="auth-wrapper">
          <form onSubmit={onLogin}>
            <div className="form-section small">
              <h1>Welcome back</h1>
              <h3>Please login to your Secure Trade Club to continue</h3>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <CustomInput
                    placeholder="Email"
                    error={errors.email}
                    registerFn={register('email')}
                    value={field.value}
                    toolTipMessage={errors.email?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <CustomPasswordInput
                    placeholder="Password"
                    error={errors.password}
                    registerFn={register('password')}
                    value={field.value}
                    showPasswordRules={!!errors.password}
                    toolTipMessage={errors.password?.message}
                  />
                )}
              />
              <NextLink href={routes.forgotPassword({ returnUrl })}>
                <a className="self-end font-bold text-gray-8a">
                  <div>Forgot password?</div>
                </a>
              </NextLink>
              <div className="w-full text-center mt-6">
                <button className="btn-new btn-primary" type="submit">
                  Log In
                </button>
              </div>
              <div className="flex flex-row mt-6 w-full gap-x-2 items-center">
                <hr className="flex-1 border-gray-d9" />
                <span className="text-gray-d9">OR</span>
                <hr className="flex-1  border-gray-d9" />
              </div>
              <div className="w-full text-center mt-6">
                <FacebookLogin returnUrl={returnUrl} isLogin={true} />
              </div>
              <div className="additional-links">
                <p>
                  Don't have Secure Trade Club Account?{' '}
                  <NextLink href={routes.signUp({ returnUrl })}>
                    <a>
                      <span>Sign Up here</span>
                    </a>
                  </NextLink>
                  .
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
